import { Injectable, OnDestroy } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';

@Injectable()
export class TabTitleService implements OnDestroy {
	translation = '';

	constructor(
		private readonly _translateService: TranslateService,
		private readonly _titleService: Title
	) {
		this._translateService.onLangChange.subscribe(() => {
			this.setTabTitle(this.translation);
		});
	}

	setTabTitle(newTitle: string): void {
		this.translation = newTitle;
		this._translateService.get(this.translation).subscribe({
			next: (translate) => this._titleService.setTitle(translate),
			error: (err) => console.error(err)
		});
	}

	ngOnDestroy(): void {
		this.setTabTitle('tabTitle.index');
	}
}
